'use strict';

const showLoader = function(button) {
	button.classList.add('c-button--loading');
};

const hideLoader = function(button) {
	button.classList.remove('c-button--loading');
};

module.exports = {
	showLoader: showLoader,
	hideLoader:  hideLoader
};