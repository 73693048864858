'use strict';

const notify = require('notify');

const cButton = require('./c-button');

const handleSubmit = function(e) {

	const resetForm = e.target.closest('form[name="reset"]');

	if (resetForm) {

		e.preventDefault();

		const submitButton = resetForm.elements.submit;

		cButton.showLoader(submitButton);

		window.fetch('/index.cfm?corporate=main.processResetPassword', {
			method: 'POST',
			body: new FormData(resetForm)
		})
		.then(function(response) {
			return response.ok ? response.json() : Promise.reject(response);
		}).then(function(response) {
	
			console.log('the json response from the server', response);
	
			if (response.status.code) {
	
				window.location.href = response.redirectUrl;
	
			} else {
	
				var target = resetForm.querySelector('[for="' + response.errorSourceName + '"]');

				if (!target) {
					target = resetForm.querySelector('[name="' + response.errorSourceName + '"]');
				}

				notify.show({
					target: target,
					position: 'top-left',
					msg: response.status.msg,
					closeOnClick: true
				});

				resetForm.querySelector('[name="' + response.errorSourceName + '"]').focus();
	
			}
	
		})
		.catch(function(error) {
	
			console.warn(error);
	
			notify.show({
				target: submitButton,
				position: 'top-left',
				msg: 'Something went wrong. Please try again.',
				closeOnClick: true
			});
			
		})
		.finally((function() {
	
			cButton.hideLoader(submitButton);
			
		}));

	}

};

const init = function() {

	document.addEventListener('submit', handleSubmit);

};

module.exports = {
	init: init
};