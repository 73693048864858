'use strict';

// const cDiscount = require('./c-discount');
const login = require('./login');
const recoverPassword = require('./recover-password');
const resetPassword = require('./reset-password');
const activateUser = require('./activate-user');
const activateAccount = require('./activate-account');
const verifyIdentity = require('./verify-identity');

const init = function() {

	// cDiscount.init();
	login.init();
	recoverPassword.init();
	resetPassword.init();
	activateUser.init();
	activateAccount.init();
	verifyIdentity.init();

};

module.exports = {
	init:init
};