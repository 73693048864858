'use strict';

const notify = require('notify');

const cButton = require('./c-button');

//const systemRecaptcha = require('recaptcha');

//const $ = require("jquery");

//var $recaptchaForm;

/*
const recaptchaChallengeCallback = function(token) {

	console.log('challenge completed...submit the form');

	$recaptchaForm.find('input[name="recaptchaToken"]').val(token);
	
	handleSubmit();

};
*/



var verifyForm;
var dom = {};

const handleVerifySubmit = function(e) {

	const verifyForm = e.target.closest('form[name="verify"]');

	if (verifyForm) {

		e.preventDefault();

		const verifyButton = verifyForm.elements.submit;

		cButton.showLoader(verifyButton);

		window.fetch('/index.cfm?corporate=main.processVerifyIdentity', {
			method: 'POST',
			body: new FormData(verifyForm)
		})
		.then(function(response) {
			return response.ok ? response.json() : Promise.reject(response);
		}).then(function(response) {
	
			console.log('the json response from the server', response);
	
			if (response.errorCode && response.errorCode == 108) {

				window.location.href = 'login?msg=' + response.status.msg;

			} else if (response.status.code) {
	
				window.location.href = response.redirectUrl;
	
			} else {
	
				var target = verifyForm.querySelector('[for="' + response.errorSourceName + '"]');

				if (!target) {
					target = verifyForm.querySelector('[name="' + response.errorSourceName + '"]');
				}

				notify.show({
					target: target,
					position: 'top-left',
					msg: response.status.msg,
					closeOnClick: true
				});

				verifyForm.querySelector('[name="' + response.errorSourceName + '"]').focus();
	
			}

		})
		.catch(function(error) {
	
			notify.show({
				target: verifyButton,
				position: 'top-left',
				msg: 'Something went wrong. Please try again.',
				closeOnClick: true
			});
			
		})
		.finally((function() {
	
			cButton.hideLoader(verifyButton);
			
		}));

	}

};


const resendVerify = function(e) {

	const verifyForm = e.target.closest('form[name="verify"]');

	if (verifyForm){

		e.preventDefault();

		const verifyButton = verifyForm.elements.submit;

		cButton.showLoader(verifyButton);

		const resendLink = $('a#resend')[0];

		window.fetch('/index.cfm?corporate=main.resendVerifyIdentity', {
			method: 'POST',
			body: new FormData(verifyForm)
		})
		.then(function(response) {
			return response.ok ? response.json() : Promise.reject(response);
		}).then(function(response) {
			
			console.log('the json response from the server', response);
			
			console.info(response);

			if (response.status.code) {
				
				notify.show({
					target: resendLink,
					position: 'top-left',
					msg: response.output.MSG,
					closeOnClick: true
				});
				
			} else {
	
				notify.show({
					target: resendLink,
					position: 'top-left',
					msg: response.status.msg,
					closeOnClick: true
				});
	
			}

		})
		.catch(function(error) {
	
			console.warn(error);
	
			notify.show({
				target: resendLink,
				position: 'top-left',
				msg: 'Something went wrong. Please try again.',
				closeOnClick: true
			});
			
		})
		.finally((function() {
	
			cButton.hideLoader(verifyButton);
			
		}));

	}

};

const init = function() {

	const isVerify = $('form[name="verify"]').length;

	if (isVerify){

		dom.verifyButton = $('button.verifyButton');

		dom.verifyButton.click(handleVerifySubmit);

		dom.resendLink = $('a#resend');

		dom.resendLink.click(resendVerify);

	}


};

module.exports = {
	init: init
};