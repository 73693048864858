'use strict';

const notify = require('notify');

const cButton = require('./c-button');

const systemRecaptcha = require('recaptcha');

const $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var $recaptchaForm;
var loginForm;
var dom = {};

const recaptchaChallengeCallback = function(token) {

	console.log('challenge completed...submit the form');

	$recaptchaForm.find('input[name="recaptchaToken"]').val(token);
	
	loginFormSubmit();

};

const handleLoginSubmit = function(e) {

	loginForm = e.target.closest('form[name="login"]');

	if (loginForm) {

		e.preventDefault();

		$recaptchaForm = $(loginForm);

		const loginButton = loginForm.elements.login;

		cButton.showLoader(loginButton);

		if ($recaptchaForm.find('input[name="recaptchaToken"]').length) {

			if (window.grecaptcha.getResponse()) {

				recaptchaChallengeCallback(window.grecaptcha.getResponse());

			} else {

				window.grecaptcha.execute();

			}

		} else {

			loginFormSubmit();

		}

	}

};

const loginFormSubmit = function() {

	const loginButton = loginForm.elements.login;

	window.fetch('/index.cfm?corporate=main.processLogin', {
		method: 'POST',
		body: new FormData(loginForm)
	})
	.then(function(response) {
		return response.ok ? response.json() : Promise.reject(response);
	})
	.then(function(response) {

		console.log('the json response from the server', response);

		if (response.status.code) {

			if (response.hasOwnProperty('output') && response.output.hasOwnProperty('PREFERENCE2FA')) {

				$('input[name="phone"]').val(response.output.PHONE);
				$('input[name="preference2FA"]').val(response.output.PREFERENCE2FA);
				loginForm.action = response.redirectUrl;				
				loginForm.submit();

			} else {

				window.location.href = response.redirectUrl;

			}

		} else {

			var target = loginForm.querySelector('[for="' + response.errorSourceName + '"]');

			if (!target) {
				target = loginForm.querySelector('[name="' + response.errorSourceName + '"]');
			}

			if (!target) {
				target = loginForm.querySelector('[name="login"]');
			}

			notify.show({
				target: target,
				position: 'top-left',
				msg: response.status.msg,
				closeOnClick: true
			});

			loginForm.querySelector('[name="' + response.errorSourceName + '"]').focus();

			window.grecaptcha.reset();

		}

	})
	.catch(function(error) {

		notify.show({
			target: loginButton,
			position: 'top-left',
			msg: 'Something went wrong. Please try again.',
			closeOnClick: true
		});
		
	})
	.finally((function() {

		cButton.hideLoader(loginButton);
		
	}));

};

const init = function() {

	const isLogin = $('form[name="login"]').length;

	if(isLogin){

		const isMsg = $('div#bubbleMsg').length;

		dom.loginButton = $('button.loginButton');

		dom.loginButton.click(handleLoginSubmit);

		systemRecaptcha.init()
			.then(function() {

				window.grecaptcha.render(document.querySelector('.g-recaptcha'), {
					callback: recaptchaChallengeCallback
				}, true);

			});

		if(isMsg){

			const Msg = $('div#bubbleMsg')[0].innerHTML;
			
			notify.show({
				target: dom.loginButton,
				position: 'top-left',
				msg: Msg,
				closeOnClick: true
			});

		}

	}

};

module.exports = {
	init: init
};