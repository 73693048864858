'use strict';

const notify = require('notify');

const cButton = require('./c-button');

const recoverRecaptcha = require('recaptcha');

var $recaptchaForm;
var recoveryForm;

const recoverChallengeCallback = function(token) {

	console.log('challenge completed...submit the form');

	$recaptchaForm.find('input[name="recaptchaToken"]').val(token);

	recoverFormSubmit();

}

const handleRecoverSubmit = function(e) {

	recoveryForm = e.target.closest('form[name="recovery"]');

	if (recoveryForm) {

		e.preventDefault();

		$recaptchaForm = $(recoveryForm);

		const submitButton = recoveryForm.elements.submit;

		cButton.showLoader(submitButton);

		if ($recaptchaForm.find('input[name="recaptchaToken"]').length) {

			if (window.grecaptcha.getResponse()) {

				recoverChallengeCallback(window.grecaptcha.getResponse());

			} else {

				window.grecaptcha.execute();

			}

		} else {

			recoverFormSubmit();

		}

	}

};

const recoverFormSubmit = function() {

console.info(recoveryForm);

	const recoveryButton = recoveryForm.elements.submit;

	window.fetch('/index.cfm?corporate=main.processRecoverPassword', {
		method: 'POST',
		body: new FormData(recoveryForm)
	})
	.then(function(response) {

		return response.ok ? response.json() : Promise.reject(response);

	})
	.then(function(response) {

		console.log('the json response from the server', response);

		if (response.status.code) {

			document.querySelector('.recover-password').classList.add('recover-password--confirmation');

		} else {

			var target = recoveryForm.querySelector('[for="' + response.errorSourceName + '"]');

			if (!target) {
				target = recoveryForm.querySelector('[name="' + response.errorSourceName + '"]');
			}

			notify.show({
				target: target,
				position: 'top-left',
				msg: response.status.msg,
				closeOnClick: true
			});

			recoveryForm.querySelector('[name="' + response.errorSourceName + '"]').focus();

			window.grecaptcha.reset();

		}

	})
	.catch(function(error) {

		notify.show({
			target: recoveryButton,
			position: 'top-left',
			msg: 'Something went wrong. Please try again.',
			closeOnClick: true
		});
		
	})
	.finally((function() {

		cButton.hideLoader(recoveryButton);
		
	}));

}

const init = function() {

	const isRecovery = $('form[name="recovery"]').length;

	if(isRecovery){

		document.addEventListener('submit', handleRecoverSubmit);

		recoverRecaptcha.init()
			.then(function() {

				window.grecaptcha.render(document.querySelector('.g-recaptcha'), {
					callback: recoverChallengeCallback
				}, true);

			});

	}

};

module.exports = {
	init: init
};






