'use strict';

const notify = require('notify');

const cButton = require('./c-button');

const detailText = {
	5: "Tell us your friend's name", 
	14: "Tell us more:",
	49: "Who's class did you take?"
};

const phoneSelect2FA = function(e) {

	var collection = e.target.selectedOptions;
	var selectedVal = collection[0].value;
	
	const activateForm = e.target.closest('form[name="activate"]');

	if (activateForm) {

		if (selectedVal === 'different') {

			activateForm.dataset.phone2fa = true;

		} else {

			activateForm.dataset.phone2fa = false;

		}

	}

};

const selectReferral = function(e) {

	var collection = e.target.selectedOptions;
	var selectedVal = collection[0].value;

	var referralLabel = document.getElementById("referral_label");
	var referralField = document.getElementById("referral");

	if (detailText[selectedVal]) {

		referralLabel.innerHTML = '<div class="c-form-control-label__title">' + detailText[selectedVal] + '</div>';
		referralLabel.style.display = 'block';
		referralField.style.display = 'block';

	} else {
		
		referralLabel.style.display = 'none';
		referralField.style.display = 'none';

	}
	
};

const selectCountry = function(e) {

	var collection = e.target.selectedOptions;
	var selectedVal = collection[0].value;

	var stateSelect = document.getElementById("state_code");
	var numOptions = stateSelect.options.length;

	for (var i = numOptions - 1; i >= 0; i--){
		stateSelect.remove(i);
	}

	if (selectedVal) {

		window.fetch('index.cfm?corporate=main.countryProvinceList&countryId='+selectedVal,{
				method: 'GET'
			}).then(function(response) {

				return response.ok ? response.json() : Promise.reject(response);

			}).then(function(response) {

				//populate the select
				for (var i = 0; i < response.provinceList.length; i++){ //country_code: 'US', province_code: 'AL', name: 'Alabama', active: 1
					stateSelect.add(new Option(response.provinceList[i].name,response.provinceList[i].province_code));
				}

		});

	}

};

const handleSubmit = function(e) {

	e.preventDefault();

	const activateForm = e.target.closest('form[name="activate"]');

	if (activateForm) {

		window.fetch('/index.cfm?corporate=main.processActivateAccount', {
			method: 'POST',
			body: new FormData(activateForm)
		}).then(function(response) {

			return response.ok ? response.json() : Promise.reject(response);

		}).then(function(response) {
	
			if (response.status.code) {
			
				window.location.href = response.redirectUrl;

			} else {

				var target = activateForm.querySelector('[for="' + response.errorSourceName + '"]');

				if (!target) {
					target = activateForm.querySelector('[name="' + response.errorSourceName + '"]');
				}

				notify.show({
					target: target,
					position: 'top-left',
					msg: response.status.msg,
					closeOnClick: true
				});

				activateForm.querySelector('[name="' + response.errorSourceName + '"]').focus();

			}

		}).catch(function(error) {
			console.warn(error);
	
	
			notify.show({
				target: loginButton,
				position: 'top-left',
				msg: 'Something went wrong. Please try again.',
				closeOnClick: true
			});
			
		}).finally(function() {
	
			//cButton.hideLoader(loginButton);
			
		});

	}

};

const init = function() {

	var countrySelect = document.getElementById("country_code");

	if (countrySelect){
		countrySelect.addEventListener('change', selectCountry);
	}

	var referralSelect = document.getElementById("advertiserId");

	if (referralSelect){
		referralSelect.addEventListener('change', selectReferral);
	}

	var phone2FASelect = document.getElementById("selectPhone2FA");

	if (phone2FASelect){
		phone2FASelect.addEventListener('change', phoneSelect2FA);
	}

	document.addEventListener('submit', handleSubmit);

	const evt = new MouseEvent("click", {
	  view: window,
	  bubbles: true,
	  cancelable: true,
	});

};

module.exports = {
	init: init
};
